<template>
    <svg
        viewBox="0 0 450 159"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M395.983 29.9329C324.678 -22.0684 200.877 -3.6031 74.3153 66.6101C72.7704 67.4671 73.3629 69.8107 75.1295 69.8107H87.4541C87.6958 69.8107 87.9436 69.7558 88.1643 69.6573C263.162 -8.46885 310.674 13.265 394.638 32.7544C396.404 33.1645 397.448 31.0014 395.983 29.9329Z"
            fill="url(#paint0_linear_3979_12677)"
        />
        <mask
            id="mask0_3979_12677"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="73"
            y="0"
            width="326"
            height="70"
        >
            <path
                d="M398.486 31.53C333.933 -22.7368 202.36 -4.32796 74.312 66.613C72.7667 67.4691 73.3625 69.8098 75.1292 69.8098H86.2315C87.279 69.8098 88.3498 69.573 89.3067 69.1468C265.338 -9.25176 311.915 13.8097 397.494 33.4124C398.64 33.6749 399.386 32.2865 398.486 31.53Z"
                fill="url(#paint1_linear_3979_12677)"
            />
        </mask>
        <g mask="url(#mask0_3979_12677)">
            <g filter="url(#filter0_d_3979_12677)">
                <path
                    d="M443.436 70.2236L380.725 102.77C369.348 62.286 357.328 16.5884 283.709 0.0447617C340.737 0.0447617 394.39 2.38545 443.436 70.2236Z"
                    fill="url(#paint2_linear_3979_12677)"
                />
            </g>
        </g>
        <path
            d="M443.436 70.2239L380.725 102.77L380.59 102.29C369.248 61.9273 356.545 16.7245 283.217 0.246249C341.152 -0.527983 394.39 2.38582 443.436 70.2239Z"
            fill="url(#paint3_linear_3979_12677)"
        />
        <path
            d="M33.535 158.793C54.7033 158.793 67.3486 148.32 67.3486 131.887V131.831C67.3486 118.852 59.5497 112 42.448 108.657L34.1478 107.042C25.4577 105.315 21.7811 102.808 21.7811 98.2404V98.1847C21.7811 93.394 26.1261 90.1073 33.4793 90.1073C37.9049 90.1073 42.4226 91.4486 44.9579 94.7129C46.6766 96.9259 48.9025 99.1317 51.7045 99.1317H59.5593C62.9128 99.1317 65.6189 96.2246 64.6133 93.0255C60.5305 80.0371 47.3183 74.064 33.3679 74.064C14.8735 74.064 1.50406 84.2025 1.44836 99.9673V100.023C1.44836 112.557 8.80155 120.579 25.7919 123.865L34.0921 125.481C43.5064 127.319 47.0716 129.436 47.0716 133.948V134.004C47.0716 139.129 41.8352 142.75 33.8693 142.75C29.5631 142.75 24.3302 141.623 21.4976 138.363C19.582 136.158 17.2875 133.725 14.3667 133.725H6.05927C2.76862 133.725 0.0757141 136.532 0.986614 139.694C4.90164 153.285 19.3087 158.793 33.535 158.793Z"
            fill="#253139"
        />
        <path
            d="M88.5461 152.337C89.2052 154.86 91.4843 156.62 94.0919 156.62H104.542C107.136 156.62 109.406 154.878 110.078 152.373L122.012 107.861C122.088 107.575 122.348 107.376 122.644 107.376C122.94 107.376 123.198 107.574 123.276 107.859L135.418 152.396C136.098 154.89 138.363 156.62 140.948 156.62H151.306C153.913 156.62 156.192 154.86 156.851 152.337L174.858 83.4174C175.806 79.7852 173.066 76.2365 169.312 76.2365H160.387C157.65 76.2365 155.294 78.1723 154.764 80.8579L145.248 129.043C145.19 129.336 144.933 129.547 144.634 129.547C144.347 129.547 144.097 129.352 144.027 129.073L131.917 80.5797C131.279 78.0273 128.986 76.2365 126.355 76.2365H118.967C116.32 76.2365 114.017 78.0498 113.395 80.6235L101.701 129.07C101.634 129.35 101.383 129.547 101.095 129.547C100.797 129.547 100.541 129.337 100.483 129.046L90.8117 80.841C90.2745 78.1633 87.9227 76.2365 85.1917 76.2365H76.0859C72.3317 76.2365 69.5911 79.7852 70.54 83.4174L88.5461 152.337Z"
            fill="#253139"
        />
        <path
            d="M183.954 150.888C183.954 154.054 186.52 156.62 189.686 156.62H198.666C201.832 156.62 204.398 154.054 204.398 150.888V81.9685C204.398 78.8028 201.832 76.2365 198.666 76.2365H189.686C186.52 76.2365 183.954 78.8028 183.954 81.9685V150.888Z"
            fill="#253139"
        />
        <path
            d="M215.78 150.888C215.78 154.054 218.346 156.62 221.512 156.62H229.434C232.599 156.62 235.166 154.054 235.166 150.888V111.607C235.166 111.24 235.464 110.941 235.832 110.941C236.043 110.941 236.242 111.042 236.368 111.212L268.097 154.288C269.177 155.754 270.89 156.62 272.712 156.62H280.293C283.459 156.62 286.025 154.054 286.025 150.888V81.9685C286.025 78.8028 283.459 76.2365 280.293 76.2365H272.371C269.206 76.2365 266.639 78.8028 266.639 81.9685V120.803C266.639 121.171 266.341 121.47 265.973 121.47C265.761 121.47 265.563 121.37 265.437 121.2L233.82 78.5548C232.739 77.0965 231.031 76.2365 229.216 76.2365H221.512C218.346 76.2365 215.78 78.8028 215.78 81.9685V150.888Z"
            fill="#253139"
        />
        <path
            d="M333.799 158.793C356.527 158.793 370.287 145.256 370.287 123.141V118.512C370.287 115.346 367.721 112.78 364.555 112.78H341.592C338.427 112.78 335.86 115.346 335.86 118.512V121.086C335.86 124.251 338.427 126.818 341.592 126.818H349.229C349.959 126.818 350.542 127.425 350.511 128.155C349.954 136.399 343.548 141.858 334.245 141.858C322.547 141.858 314.971 132.054 314.971 116.178V116.066C314.971 100.469 321.823 90.9986 333.242 90.9986C339.03 90.9986 344.688 93.4779 347.627 98.2301C349.04 100.515 351.253 102.474 353.939 102.474H363.173C366.564 102.474 369.266 99.5073 368.212 96.284C363.526 81.9517 348.236 74.064 333.242 74.064C309.122 74.064 294.192 90.2187 294.192 116.289V116.401C294.192 142.805 309.066 158.793 333.799 158.793Z"
            fill="#253139"
        />
        <circle cx="414.175" cy="90.652" r="35.825" fill="white" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M409.589 117.879C429.375 117.879 445.414 101.84 445.414 82.054C445.414 77.7014 444.638 73.5301 443.217 69.671C447.484 75.5681 450 82.8162 450 90.652C450 110.438 433.961 126.477 414.175 126.477C398.742 126.477 385.588 116.718 380.548 103.035C387.056 112.028 397.64 117.879 409.589 117.879Z"
            fill="#CCD4DA"
        />
        <path
            d="M414.162 109.546C423.608 109.546 429.805 104.18 429.805 96.0189V76.8246C429.805 73.7515 428.117 72.0134 425.17 72.0134C422.248 72.0134 420.56 73.7515 420.56 76.8246V95.0366C420.56 99.2684 418.268 101.787 414.162 101.787C410.031 101.787 407.739 99.2684 407.739 95.0366V76.8246C407.739 73.7515 406.051 72.0134 403.129 72.0134C400.207 72.0134 398.494 73.7515 398.494 76.8246V96.0189C398.494 104.18 404.691 109.546 414.162 109.546Z"
            fill="#49BB54"
        />
        <defs>
            <filter
                id="filter0_d_3979_12677"
                x="268.233"
                y="-8.55323"
                width="182.655"
                height="125.653"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dx="-4.0124" dy="2.866" />
                <feGaussianBlur stdDeviation="5.73199" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.0901961 0 0 0 0 0.0941176 0 0 0 0 0.101961 0 0 0 0.35 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_3979_12677"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_3979_12677"
                    result="shape"
                />
            </filter>
            <linearGradient
                id="paint0_linear_3979_12677"
                x1="304.435"
                y1="-19.5489"
                x2="117.14"
                y2="66.43"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#29A635" />
                <stop offset="1" stop-color="#3CBD49" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_3979_12677"
                x1="279.722"
                y1="-23.3852"
                x2="83.1142"
                y2="67.1808"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#289B33" />
                <stop offset="1" stop-color="#39AC44" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_3979_12677"
                x1="317.915"
                y1="-0.482061"
                x2="410.307"
                y2="53.6813"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#65D470" />
                <stop offset="1" stop-color="#49BB54" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_3979_12677"
                x1="330.731"
                y1="6.62982"
                x2="415.204"
                y2="54.3828"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#65DD71" />
                <stop offset="1" stop-color="#37B943" />
            </linearGradient>
        </defs>
    </svg>
</template>
